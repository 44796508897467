
::v-deep {
    .v-expansion-panel {
        &-header {
            min-height: 54px;
        }
        &--active {
            > .v-expansion-panel-header {
                min-height: 54px;
            }
        }
    }
}
