
::v-deep {
    .v-stepper {
        --stepper-icon-size: 80px;
        border-radius: 0;
        box-shadow: none;
        &__header {
            align-items: flex-start;
            justify-content: center;
            padding: 10px;
            box-shadow: none;
        }
        &__step {
            padding: 0;
            flex-basis: auto;
            &__step {
                display: none;
            }
            &--complete {
                .v-stepper__icon {
                    color: var(--v-primary-base);
                    border-color: var(--v-primary-base);
                    background-color: #fff;
                }
                .tit,
                .v-stepper__arrow .icon-svg {
                    color: var(--v-primary-base) !important;
                }
                .txt {
                    color: var(--v-grey-darken4) !important;
                }
            }
            &--editable:hover {
                background: transparent !important;
            }
        }
        &__label {
            position: relative;
            display: flex !important;
            text-align: center;
            align-items: center;
            padding: 0 26px;
            margin: 10px 0;
            text-shadow: none !important;
        }
        &__icon,
        &__arrow {
            -webkit-transition: all ease-out 0.2s;
            -ms-transition: all ease-out 0.2s;
            transition: all ease-out 0.2s;
        }
        &__icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: var(--stepper-icon-size);
            height: var(--stepper-icon-size);
            border-radius: 100%;
            margin-bottom: 10px;
            color: var(--v-grey-lighten2);
            border: 1px solid var(--v-grey-lighten5);
            background-color: var(--v-grey-lighten5);
        }
        &__arrow {
            position: absolute;
            top: calc(var(--stepper-icon-size) / 2);
            right: 0;
            transform: translate(50%, -50%);
            display: flex;
            .icon--direction-right {
                width: 10px;
            }
        }
        &__content {
            padding: 0;
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    ::v-deep {
        .v-stepper {
            --stepper-icon-size: 100px;
            &__header {
                padding: 20px;
            }
            &__icon {
                margin-bottom: 20px;
            }
            &__arrow {
                .icon--direction-right {
                    width: 16px;
                }
            }
            &__label {
                padding: 0 36px;
            }
        }
    }
}
@media (min-width: 1024px) {
    ::v-deep {
        .v-stepper {
            &__header {
                padding: 30px;
            }
            &__label {
                padding: 0 46px;
            }
        }
    }
}
@media (min-width: 1200px) {
}
